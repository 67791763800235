import React, { useState } from 'react';
import { Row, Col, Input, Typography, Alert, Spin } from 'antd';

import { Preguntas } from './Preguntas';
import { Estadisticas } from './Estadisticas';
import Firebase from '../../utils/Firebase';
import 'antd/dist/antd.css';

const { Search } = Input;
const { Text } = Typography;

const nombre_evento = 'iso-28000-2007';

const Usuario = () => {

    const [usuario, setUsuario] = useState({});
    const [etapa, setEtapa] = useState(0);
    const [error_correo, setErrorCorreo] = useState(false);
    const [cargando, setCargando] = useState(false);
    const [preguntas, setPreguntas] = useState([]);
    const [estadisticas, setEstadisticas] = useState([]);

    const buscarUsuario = (value) => {
        if (value !== '' && value !== ' ') {
            setErrorCorreo(false);
            setCargando(true);
            Firebase.obtener_objeto(nombre_evento + '/usuarios', 'correo', value, siguienteEtapa);
        } else {
            setErrorCorreo(true);
        }
    }

    const siguienteEtapa = (datos) => {
        switch (etapa) {
            case 0:
                setUsuario(datos);
                let existe_respuestas = datos.respuestas;
                    Firebase.obtener_objetos(nombre_evento + '/preguntas', false, (datos) => {
                        
                        setPreguntas(datos);
                        Firebase.obtener_objetos(nombre_evento + '/usuarios', true, (datos) => {
                            setEstadisticas(datos);
                            setCargando(false);
                            if (typeof existe_respuestas !== 'undefined') {
                                setEtapa(2);
                            } else {
                                setEtapa(1);
                            }
                        });
                    })
                
                break;
            case 1:
                setUsuario(datos);
                Firebase.obtener_objetos(nombre_evento + '/usuarios', true, (datos) => {

                    setEstadisticas(datos);
                    setCargando(false);
                    setEtapa(2);
                });
                break;
            default:

                break;
        }
    }

    const guardarRespuestas = (respuestas) => {

        const actualizar_usuario = {
            correo: usuario.correo,
            respuestas: respuestas,
            key: usuario.key,
            respondio: true
        }
        setCargando(true);
        Firebase.escribir(nombre_evento + '/usuarios', usuario.key, actualizar_usuario, siguienteEtapa);

    }

    return (
        <Spin tip="Cargando..." spinning={cargando}>
            <Row align="middle">
                
                <Col span={24} style={{ width: '100%', height: '20px' }}></Col>
                
                
                {etapa === 0 ?
                    (
                        <Col xs={{ span: 20, offset: 2 }} md={{ span: 12, offset: 6 }} lg={{ span: 8, offset: 8 }}>
                            <Text style={{ fontWeight: 'bold', fontSize: '16px' }} >Para comenzar ingresa un correo electrónico</Text>
                            <br /><br />
                            <Search
                                placeholder="correo electrónico"
                                enterButton="Entrar"
                                size="large"
                                onSearch={buscarUsuario}
                            />
                            {error_correo &&
                                <><br /><br />
                                    <Alert type="error" message="Por favor registra un correo válido" banner />
                                </>
                            }
                        </Col>
                    )
                    :
                    etapa === 1 ?
                        (
                            <Col xs={{ span: 20, offset: 2 }} md={{ span: 12, offset: 6 }} lg={{ span: 10, offset: 7 }} >
                                <Preguntas preguntas={preguntas} onResponse={guardarRespuestas} />
                            </Col>
                        )
                        :
                        (
                            <Col xs={{ span: 22, offset: 1 }} md={{ span: 18, offset: 3 }} lg={{ span: 18, offset: 3 }}>
                                <Estadisticas estadisticas={estadisticas} preguntas={preguntas} />
                            </Col>
                        )
                }
            </Row>
        </Spin>
    );

}

export default Usuario;