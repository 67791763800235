import React, { useState } from 'react';
import { Row, Col, Carousel, Button, Typography, Input } from 'antd';

const { Text } = Typography;


let it_pregunta = 1;
let usuario_respuestas = {};

export const Preguntas = (props) => {

    const [respuesta_elegida, setRespuestaElegida] = useState(false);
    const { preguntas, onResponse } = props;
    const refCarouselPreguntas = React.createRef();



    const onChangeRespuesta = (value) => {
        usuario_respuestas['pregunta' + it_pregunta] = value.target.value;
        setRespuestaElegida(true);
    }

    const siguientePregunta = () => {
        setRespuestaElegida(false);
        ++it_pregunta;
        if (it_pregunta > preguntas.length) {
            onResponse(usuario_respuestas);
        } else {
            refCarouselPreguntas.current.next();
        }

    }

    let divs_preguntas = preguntas.map(pregunta => {

        const respuestas = Object.values(pregunta.respuestas).map(respuesta => {
            return (
                <Row align="middle" style={{ textAlign: 'center', marginTop: '15px' }}>

                    <Col span={1} style={{ textAlign: 'right' }}>

                        <Input name="resp" type="radio" value={respuesta} onClick={onChangeRespuesta} style={{border:'0px solid white !important'}} />
                    </Col>
                    <Col span={22} offset={0} style={{ textAlign: 'left', paddingLeft:'5px' }}>

                        <Text>{respuesta}</Text>
                    </Col>
                </Row>



            );
        });

        return (
            <div>
                <Row style={{border:'1px solid lightgray', background: 'white'}}>
                    <Col span={24} style={{ backgroundColor: '#2491fb', paddingTop:'10px', paddingBottom:'10px' }} >
                        <Text strong style={{ color: 'white', fontSize: '16px' }}>{pregunta.pregunta}</Text>
                    </Col>
                    <Col span={22} offset={1}>

                        {respuestas}


                    </Col>
                    <Col span={24} style={{  textAlign:'right' }}>
                    <Button type="primary" onClick={siguientePregunta} disabled={!respuesta_elegida} >SIGUIENTE</Button>
                    </Col>




                </Row>


            </div>
        );


    });

    return (
        <Carousel dots={false} ref={refCarouselPreguntas} effect="fade">
            {divs_preguntas}
        </Carousel>
    );

}