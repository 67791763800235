import * as firebase from 'firebase'

var firebaseConfig = {
    apiKey: "AIzaSyA9dMPpBQ3FjlSLrtbw2LUOJhLkVqq731s",
    authDomain: "encuesta-live.firebaseapp.com",
    databaseURL: "https://encuesta-live.firebaseio.com",
    projectId: "encuesta-live",
    storageBucket: "encuesta-live.appspot.com",
    messagingSenderId: "1084506735627",
    appId: "1:1084506735627:web:47f90e1e1fce7b3e79730e",
    measurementId: "G-BFXE0PML21"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();



class Firebase {

    constructor(){
        this.conexion = firebase.database();
    }

    obtener_objeto = (url, campo, valor, funct) => {
        this.conexion.ref(url).once('value', (objetos) => {

            if (this.collectionToArr(objetos).length > 0) {

                let objeto_buscado = this.collectionToArr(objetos).filter(objeto => {
                    if (objeto[campo] === valor) {
                        return true;
                    } else {
                        return false;
                    }
                });
                if(objeto_buscado.length === 1){
                    return funct(objeto_buscado[0]);
                }else{
                    const datos_objeto = {};
                    datos_objeto[campo] = valor;
                    this.escribir(url, undefined, datos_objeto, funct);
                }
            } else {
                const datos_objeto = {};
                datos_objeto[campo] = valor;
                this.escribir(url, undefined, datos_objeto, funct);
            }
        })
    }

    escribir = (url, id, objeto, funct) => {

        let key = '';

        if (id === undefined) {
            key = this.conexion.ref(url).push().key;
        } else {
            key = id;
        }

        this.conexion.ref(url).child(key).set(objeto, () => {
            objeto['key'] = key;
            objeto['respondio'] = false;
            return funct(objeto);
        });

    }

    obtener_objetos = (url, escuchar ,funct) => {

        if(escuchar){
            this.conexion.ref(url).orderByChild("num_pregunta").on('value', (objetos) => {
                return funct(this.collectionToArr(objetos));
            })
        }else{
            this.conexion.ref(url).orderByChild("num_pregunta").once('value', (objetos) => {
                return funct(this.collectionToArr(objetos));
            })
        }
    }

    collectionToArr = (collection) => {
        var rows = [];

        if (collection && collection.val()) {

            collection.forEach((obj) => {
                let item = obj.val();
                item.key = obj.key;
                rows.push(item);
            });
        }

        return rows;
    }


}

export default Firebase = new Firebase();
